<style lang="less" scoped>
  .transfer-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    .title {
      font-size: 14px;
      color: #333;
      height: 30px;
      line-height: 30px;
    }
    .noSelect {
      width: 50%;
      padding: 0 15px;
      .searchBar {
        margin-bottom: 10px;
        .el-input__inner {
          border-radius: 0 !important;
        }
      }
      .el-table {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
      .el-pagination {
        width: 100%;
      }
    }

    .hasSelect-wrap {
      width: 50%;
      height: 100%;
      padding: 0 15px;
      .hasSelect {
        width: 100%;
        height: 405px;
        border: 1px solid #ddd;
        overflow-x: hidden;
        overflow-y: auto;
        .selected-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          line-height: 48px;
          padding: 0 20px;
          border-bottom: 1px solid #ddd;
          font-size: 14px;
          .name {
            .sort {
              padding-right: 15px;
            }
          }
          .el-icon-delete {
            cursor: pointer;
          }
        }
        .no-selectData {
          height: 60px;
          line-height: 60px;
          width: 100%;
          font-size: 14px;
          text-align: center;
          color: #999;
        }
      }
    }
  }
</style>

<style lang="less">
  .transfer-searchBar {
    .el-input__inner {
      border-radius: 0 !important;
    }
  }
</style>

<template>
  <div class="transfer-wrap">
    <div class="noSelect">
      <div class="title">选择列表</div>

      <el-input
        clearable
        class="transfer-searchBar"
        size="small"
        v-model="searchKey"
        placeholder="请输入关键字搜索"
        @input="onSearch"
      ></el-input>

      <el-table
        v-loading="tableLoading"
        ref="refTable"
        row-key="id"
        :data="dataList"
        :show-header="false"
        @select="changeCheck"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>
              {{ scope.row.name
              }}{{ `${scope.row.number ? `(${scope.row.number})` : ""}` }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;"
        small
        layout="prev, pager, next"
        :total="total"
        :page-size="7"
        @current-change="changeTablePage"
      ></el-pagination>
    </div>

    <div class="hasSelect-wrap">
      <div class="title">已选数据展示</div>

      <div class="hasSelect">
        <vue-draggable v-model="selectList" @end="changeSort">
          <div
            class="selected-row"
            v-for="(item, index) in selectList"
            :key="item.id"
          >
            <div class="name">
              <span class="sort">{{ index + 1 }}</span>
              <span>
                {{ item.name }}{{ `${item.number ? `(${item.number})` : ""}` }}
              </span>
            </div>
            <div class="tool">
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="top"
              >
                <i class="el-icon-delete" @click="handleDel(item, index)"></i>
              </el-tooltip>
              <slot name="func" :item="item"></slot>
            </div>
          </div>
        </vue-draggable>

        <div class="no-selectData" v-if="!this.selectList.length">
          暂无选中数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vueDraggable from "vuedraggable";

  export default {
    components: {
      vueDraggable,
    },
    data() {
      return {
        tableLoading: false,
        searchKey: "",
        dataList: [],
        selectList: [],
        total: 0,
        pager: {
          page: 1,
          size: 7,
        },
      };
    },
    computed: {
      isFunction() {
        return (
          Object.prototype.toString.call(this.queryAction) ===
            "[object Function]" ||
          Object.prototype.toString.call(this.queryAction) ===
            "[object AsyncFunction]"
        );
      },
    },
    props: {
      queryAction: {
        type: Function,
      },
    },
    methods: {
      changeSort(value) {
        this.$emit("change", this.selectList);
      },

      // 改变选中
      changeCheck(sels, row) {
        const findIndex = this.selectList.findIndex(
          (item) => item.id == row.id
        );
        if (findIndex > -1) {
          this.selectList.splice(findIndex, 1);
        } else {
          this.selectList.push(row);
        }
        // if (!this.$parent.brandInfo.mainPoint && this.selectList.length) {
        //   this.$parent.brandInfo.mainPoint = this.selectList[0].code;
        // }
        this.$emit("change", this.selectList);
      },

      // 删除
      handleDel(item, index) {
        this.$confirm("是否删除该数据点?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.selectList.splice(index, 1);
            this.$refs.refTable.toggleRowSelection(item, false);
          })
          .catch(() => {});
      },

      // 搜索
      async onSearch(keys) {
        this.pager.page = 1;
        this.searchKey = keys;
        await this.fetchTableData();
      },

      // 改变页
      async changeTablePage(page) {
        this.pager.page = +page;
        await this.fetchTableData();
      },

      // 拉取表格数据
      async fetchTableData(hasSelectList) {
        if (hasSelectList && hasSelectList.length) {
          this.searchKey = "";
          this.selectList = hasSelectList;
        }

        if (this.isFunction) {
          this.tableLoading = true;
          const resData = await this.queryAction(
            { key: this.searchKey },
            this.pager
          );
          if (resData.errorCode === 0 && resData.data) {
            this.dataList = [];
            const dataList = resData.data.list || [];
            this.total = resData.data.total || 0;

            this.dataList = dataList.map((item) => {
              return {
                id: item.id,
                name: item.name || item.username,
                number: item.number,
                code: item.code,
              };
            });

            // 如果有选择的勾上
            const selectUserIdList = this.selectList.map((item) => item.id);
            this.$nextTick(() => {
              this.dataList.forEach((item) => {
                if (selectUserIdList.includes(item.id)) {
                  this.$refs.refTable.toggleRowSelection(item, true);
                }
              });
            });
          }
          this.tableLoading = false;
        }
      },
    },
  };
</script>
