<style lang="less" scoped>
  .label-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-foot {
    text-align: right;
  }
</style>

<template>
  <el-form
    ref="editForm"
    :model="modeInfo"
    :rules="rules"
    label-position="top"
    class="mode-edit">
    <el-form-item
      label="模式名称"
      prop="name">
      <el-input clearable v-model="modeInfo.name" placeholder="输入模式名称"></el-input>
    </el-form-item>

    <el-form-item
      label="模式对应值"
      prop="value">
      <el-input clearable
        type="number" :min="0" v-model.number="modeInfo.value" placeholder="输入模式对应值"></el-input>
    </el-form-item>

    <el-form-item
      label="模式顺序"
      prop="sort">
      <el-input clearable
        type="number" :min="0" v-model.number="modeInfo.sort" placeholder="输入模式顺序"></el-input>
    </el-form-item>

    <el-form-item>
      <div class="label-item" slot="label">
        <span>辅助功能</span>&nbsp;&nbsp;
        <i @click="handleAdd" style="width: 20px;font-size: 24px;margin-left: 10px;"
          class="text-primary el-icon-circle-plus"></i>
      </div>
      <el-table
        :data="modeInfo.funcList"
        height="370"
        border
        style="width: 100%">
        <el-table-column
          width="80"
          prop="icon"
          label="图标"
          align="center">
          <template slot-scope="scope">
            <el-upload
              class="avatar-uploader"
              action=""
              :http-request="(e)=>uploadImg(e, scope.row, 'onIcon')"
              :show-file-list="false">
              <img
                v-if="scope.row.onIcon"
                width="40"
                height="40"
                :src="API_ROOT+scope.row.onIcon" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="功能名称"
          align="center">
          <template slot-scope="scope">
            <el-input clearable v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          prop="code"
          label="功能标识"
          align="center">
          <template slot-scope="scope">
            <el-input clearable v-model="scope.row.code"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          prop="levelCode"
          label="挡位标识"
          align="center">
          <template slot-scope="scope">
            <el-input clearable v-model="scope.row.levelCode"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          prop="min"
          label="最小值"
          align="center">
          <template slot-scope="scope">
            <el-input clearable type="number" :min="0" v-model="scope.row.min"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          prop="max"
          label="最大值"
          align="center">
          <template slot-scope="scope">
            <el-input clearable type="number" :min="0" v-model="scope.row.max"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          prop="disabled"
          label="禁用"
          align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.disabled">
              <el-option 
                v-for="item in [{id: 0, name: '否'}, {id: 1, name: '是'}]"
                :key="item.id"
                :value="item.id"
                :label="item.name"
                ></el-option>
            </el-select>
            <!-- <el-input clearable type="number" :min="0" v-model="scope.row.max"></el-input> -->
          </template>
        </el-table-column>

        <el-table-column
          prop="unit"
          label="单位"
          align="center">
          <template slot-scope="scope">
            <el-input clearable v-model="scope.row.unit"></el-input>
          </template>
        </el-table-column>

        <el-table-column
          width="60"
          label="操作">
          <template slot-scope="scope">
            <span class="text-danger" @click="handleDel(scope.$index)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>

    <div class="form-foot">
      <el-button
        @click="$emit('cancel')">
        取消
      </el-button>

      <el-button
        type="primary"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {API_ROOT} from 'src/config';
import {uploadImageFile} from 'src/services/common';

export default {
  data () {
    return {
      API_ROOT,
      modeInfo: {
        sort: '',
        name: '',
        onIcon: '',
        offIcon: '',
        value: '',
        funcList: []
      },
      rules: {
        sort: [{required: true, message: '请输入', trigger: 'blur'}],
        name: [{required: true, message: '请输入', trigger: 'blur'}],
        value: [{required: true, message: '请输入', trigger: 'blur'}]
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    propModeInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    // 添加辅助功能
    handleAdd () {
      this.modeInfo.funcList.push({
        onIcon: '',
        offIcon: '',
        name: '',
        code: '',
        levelCode: '',
        min: '',
        max: '',
        unit: ''
      })
    },

    // 点击删除
    handleDel (index) {
      this.modeInfo.funcList.splice(index, 1);
    },

    // 上传图片
    async uploadImg(e, row, attr) {
      const resData = await uploadImageFile(e.file);
      if (resData.errorCode === 0 && resData.data) {
        const imgInfo = resData.data || {};
        this.$set(row, attr, imgInfo.fpicurl)
      }
    },

    // 提交表单
    handleSubmit () {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.modeInfo);
        }
      });
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        if (newVal) {
          if (this.propModeInfo) {
            this.modeInfo = cloneDeep(this.propModeInfo);
          }
        } else {
          this.modeInfo = {
            sort: '',
            name: '',
            onIcon: '',
            offIcon: '',
            value: '',
            funcList: []
          }
        }
      },
      immediate: true
    }
  }
}
</script>
