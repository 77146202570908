<style lang="less" scoped>
.page-container {
  .mainFunc-icon {
    display: inline-block;
    cursor: pointer;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border: 1px solid #eee;
    .addIcon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .base-setting {
    display: flex;
    justify-content: space-between;
  }
  .setting {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    .setMainPoint {
      margin-left: 10px;
      cursor: pointer;
      font-weight: bold;
    }

    .brand-item {
      width: 49%;
      height: 500px;
      border: 1px solid #ccc;
      margin-right: 2%;
      margin-bottom: 20px;
      border-radius: 3px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        border-bottom: 1px solid #ccc;
        font-size: 17px;
        font-weight: bold;
        .right-btn {
          height: 30px;
          line-height: 8px;
        }
      }
      .content {
        height: 360px;
      }
    }
    .content {
      padding: 10px 15px;
      .mainFunc-form {
        width: 300px;
      }
    }
  }
   .distrube-net{
        padding-top: 25px;
        padding-bottom: 15px;
      }
}
</style>

<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/pkind' }"
        >品牌型号</el-breadcrumb-item
      >
      <el-breadcrumb-item>品牌编辑</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <div class="base-setting">
        <el-form
          ref="baseForm"
          label-position="top"
          :model="brandInfo"
          :rules="rules"
        >
          <el-row>
             
            <el-col :span="6">
              <el-form-item
                style="margin-bottom: 0px"
                label-width="0px"
                prop="images"
              >
                <el-upload
                  class="mainFunc-icon"
                  action=""
                  :http-request="(e) => uploadImg(e, 1)"
                  :show-file-list="false"
                >
                  <img
                    class="addIcon"
                    v-if="brandInfo.images"
                    :src="API_ROOT + brandInfo.images"
                  />
                  <div v-else class="addIcon">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </el-upload>
                <el-input v-model="brandInfo.images" v-show="false"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="名称" prop="productName">
                <el-input style="width: 200px" v-model="brandInfo.productName"></el-input>
              </el-form-item>
            </el-col>

           
          </el-row>

          <el-row :gutter="30">
            <el-col :span="6">
              <el-form-item label="客户" prop="customerId">
                <el-select
                  clearable
                  filterable
                  remote
                  :remote-method="queryCustomers"
                  style="width: 200px"
                  v-model="brandInfo.customerId"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="型号标识" prop="belongsType">
                <el-input
                  clearable
                  style="width: 200px"
                  v-model="brandInfo.belongsType"
                  placeholder="请输入品牌编号"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="系列编号" prop="belongsSeries">
                <el-input
                  clearable
                  style="width: 200px"
                  v-model="brandInfo.belongsSeries"
                  placeholder="请输入品牌编号"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="设备型号" prop="deviceTypeId">
                <el-select
                  clearable
                  filterable
                  remote
                  :remote-method="queryDevTypes"
                  style="width: 200px"
                  v-model="brandInfo.deviceTypeId"
                >
                  <el-option
                    v-for="item in deviceTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
             <el-col :span="6">
              <el-form-item label="空气盒子" prop="sensorDevice">
                <el-select
                  clearable
                  filterable
                  style="width: 200px"
                  v-model="brandInfo.sensorDevice"
                >
                  <el-option
                    v-for="item in sensorDeviceList"
                    :key="'sensorDevice'+item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="30">
            <el-col :span="6">
              <el-form-item label="滤芯规则1:">
                <el-select
                  class="el-sel-width"
                  v-model="brandInfo.oneId"
                  placeholder="请选择一层滤芯规则"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in surplusList1"
                    :key="item.id"
                    :label="item.ruleName + '-' + item.maxValue"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="滤芯规则2:">
                <el-select
                  class="el-sel-width"
                  v-model="brandInfo.twoId"
                  placeholder="请选择二层滤芯规则"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in surplusList2"
                    :key="item.id"
                    :label="item.ruleName + '-' + item.maxValue"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="滤芯规则3:">
                <el-select
                  class="el-sel-width"
                  v-model="brandInfo.threeId"
                  placeholder="请选择滤芯规则"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in surplusList3"
                    :key="item.id"
                    :label="item.ruleName + '-' + item.maxValue"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="滤芯规则4:">
                <el-select
                  class="el-sel-width"
                  v-model="brandInfo.fourId"
                  placeholder="请选择滤芯规则"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in surplusList4"
                    :key="item.id"
                    :label="item.ruleName + '-' + item.maxValue"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="30">
            <el-col :span="6">
              <el-form-item label="滤芯链接1:">
                <el-input
                  v-model="brandInfo.oneLink"
                  placeholder="请输入一层滤芯的链接地址"
                  :disabled="!brandInfo.oneId"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="滤芯链接2:">
                <el-input
                  v-model="brandInfo.twoLink"
                  placeholder="请输入二层滤芯的链接地址"
                  :disabled="!brandInfo.twoId"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="滤芯链接3:">
                <el-input
                  v-model="brandInfo.threeLink"
                  placeholder="请输入三层滤芯的链接地址"
                  :disabled="!brandInfo.threeId"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="滤芯链接4:">
                <el-input
                  v-model="brandInfo.fourLink"
                  placeholder="请输入四层滤芯的链接地址"
                  :disabled="!brandInfo.fourId"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div>
            <el-input
              type="textarea"
              :rows="3"
              clearable
              style="width: 900px"
              v-model="brandInfo.remarks"
              placeholder="品牌备注信息"
            >
            </el-input>
          </div>
          <el-col>
             <div class="distrube-net">配网步骤</div>
            <el-input
              type="textarea"
              :rows="3"
              clearable
              style="width: 900px"
              v-model="brandInfo.distributeNet"
              placeholder="配网步骤"
            >
            </el-input>
            </el-col>
        </el-form>

        <div>
          
          <el-button type="warning" @click="handleSubmit">保存设置</el-button>
        </div>
      </div>

      <div class="setting">
        <div class="brand-item">
          <div class="title">数据点配置</div>

          <div class="content">
            <transfer-list ref="transferList" :queryAction="getDataPointList">
              <template v-slot:func="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="设为主要监控数据"
                  placement="top"
                >
                  <span
                    class="setMainPoint"
                    :class="{
                      'text-danger': scope.item.code === brandInfo.mainPoint,
                    }"
                    @click="toSetMainPoint(scope.item)"
                    ><i class="el-icon-mobile"></i
                  ></span>
                </el-tooltip>
              </template>
            </transfer-list>
          </div>
        </div>

        <!-- <div class="brand-item">
          <div class="title">主功能配置</div>

          <div class="content">
            <el-form
              ref="mainForm"
              :model="mainFuncInfo"
              :rules="mainRules"
              class="mainFunc-form"
              label-width="80px"
            >
              <el-form-item label="图标" prop="icon">
                <el-upload
                  class="mainFunc-icon"
                  action=""
                  :http-request="(e) => uploadImg(e, 2)"
                  :show-file-list="false"
                >
                  <img
                    class="addIcon"
                    v-if="mainFuncInfo.icon"
                    :src="API_ROOT + mainFuncInfo.icon"
                  />
                  <div v-else class="addIcon">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="功能名称" prop="name">
                <el-input
                  clearable
                  v-model="mainFuncInfo.name"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>

              <el-form-item label="协议标识" prop="code">
                <el-input
                  clearable
                  v-model="mainFuncInfo.code"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>

              <el-form-item label="最小值" prop="minValue">
                <el-input-number
                  v-model="mainFuncInfo.minValue"
                  controls-position="right"
                  :min="1"
                  :max="100"
                >
                </el-input-number>
              </el-form-item>

              <el-form-item label="最大值" prop="maxValue">
                <el-input-number
                  v-model="mainFuncInfo.maxValue"
                  controls-position="right"
                  :min="1"
                  :max="100"
                >
                </el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </div> -->

        <div class="brand-item">
          <div class="title">
            <span>模式及辅助功能配置</span>
            <el-button
              round
              class="right-btn"
              type="primary"
              size="mini"
              @click="handleEditMode"
            >
              添加模式
            </el-button>
          </div>

          <div class="content">
            <el-form
              ref="modeForm"
              label-width="110px"
              :rules="modeRules"
              :model="modeSetInfo"
            >
              <el-form-item label="模式协议标识" prop="code">
                <el-input
                  clearable
                  style="width: 300px"
                  v-model="modeSetInfo.code"
                  placeholder="请输入"
                >
                </el-input>
              </el-form-item>
            </el-form>

            <el-table
              :data="modeSetInfo.modeList"
              height="370"
              border
              style="width: 100%"
            >
              <el-table-column prop="name" label="模式名称"> </el-table-column>
              <el-table-column prop="value" label="模式对应值">
              </el-table-column>
              <el-table-column prop="funcList" label="是否配置辅助能">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.funcList && scope.row.funcList.length
                      ? "是"
                      : "否"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column width="60" label="操作" align="center">
                <template slot-scope="scope">
                  <span
                    class="text-primary"
                    @click="handleEditMode(scope.$index, scope.row)"
                    >编辑</span
                  >
                  <span
                    class="text-danger"
                    @click="handleRemove(scope.$index)"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- <div class="brand-item" >
          <div class="title">
            <span>滤芯配置</span>
            <el-button
              round
              class="right-btn"
              type="primary"
              size="mini"
              @click="handleFilterAdd"
            >
              添加滤芯
            </el-button>
          </div>

          <div class="content">
            <el-table
              :data="filterList"
              height="432"
              border
              style="width: 100%"
            >
              <el-table-column prop="name" label="滤芯名称">
                <template slot-scope="scope">
                  <el-input clearable v-model="scope.row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="maxValue" label="最大值">
                <template slot-scope="scope">
                  <el-input
                    clearable
                    type="number"
                    v-model.number="scope.row.maxValue"
                  ></el-input>
                </template>
              </el-table-column>

              <el-table-column width="60" label="操作" align="center">
                <template slot-scope="scope">
                  <span class="text-danger" @click="deleteFilter(scope.$index)"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div> -->
      </div>
    </div>

    <el-dialog
      title="模式编辑"
      :close-on-click-modal="false"
      :visible.async="showModeEdit"
      width="900px"
      @close="showModeEdit = false"
    >
      <mode-edit
        :visible="showModeEdit"
        :propModeInfo="currentMode"
        @submit="submitMode"
        @cancel="showModeEdit = false"
      >
      </mode-edit>
    </el-dialog>
  </div>
</template>

<script>
import { API_ROOT } from "src/config";
import { uploadImageFile } from "src/services/common";
import { getDataPointList, querySurplusRules } from "src/services/device";
import ModeEdit from "./components/mode-edit";
import TransferList from "src/components/transfer-list";
import { getAllCustomers } from "src/services/customer";
import {
  searchDevicesByType,
  getBrandInfo,
  createBrand,
  updateBrand,
} from "src/services/device";

export default {
  components: {
    ModeEdit,
    TransferList,
  },
  data() {
    return {
      API_ROOT,
      sensorDeviceList:[
         {name:'是',id:1},
         {name:'否',id:0},
      ],
      getDataPointList,
      isEdit: false,
      showModeEdit: false,
      modeEditType: "add",
      chooseModeIndex: -1,
      filterList: [],
      customerList: [],
      deviceTypeList: [],
      dataPointList: [],
      surplusList1: [],
      surplusList2: [],
      surplusList3: [],
      surplusList4: [],
      currentMode: {},
      modeSetInfo: {
        code: "",
        modeList: [],
      },
      mainFuncInfo: {
        icon: "",
        name: "",
        code: "",
        minValue: 0,
        maxValue: 10,
      },
      brandInfo: {
        distributeNet:'',
        id: "",
        images: "",
        deviceTypeId: "",
        belongsType: "",
        belongsSeries: "",
        mainPoint: "",
        mainFuncJson: "",
        modeJson: "",
        filterJson: "",
        dataPointJson: "",
        oneId: "",
        twoId: "",
        threeId: "",
        fourId: "",
        oneLink: "",
        twoLink: "",
        threeLink: "",
        fourLink: "",
      },
      rules: {
        images: [
          { required: true, message: "请上传品牌图片", trigger: "change" },
        ],
        customerId: [{ required: true, message: "请输入", trigger: "change" }],
        belongsType: [{ required: true, message: "请输入", trigger: "change" }],
        belongsSeries: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        deviceTypeId: [
          { required: true, message: "请输入", trigger: "change" },
        ],
      },
      modeRules: {
        code: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      mainRules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        minValue: [{ required: true, message: "请输入", trigger: "blur" }],
        maxValue: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 编辑模式
    handleEditMode(index, row) {
      if (row) {
        this.modeEditType = "edit";
        this.chooseModeIndex = index;
        this.currentMode = row;
      } else {
        this.modeEditType = "add";
        this.currentMode = null;
      }
      this.showModeEdit = true;
    },

    // 编辑模式
    handleRemove(index) {
      this.$confirm("确认删除该模式吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.modeSetInfo.modeList.splice(index, 1)
        })
    },

    // 编辑滤芯
    handleFilterAdd() {
      this.filterList.push({
        name: "",
        maxValue: "",
      });
    },

    // 提交模式
    submitMode(modeItem) {
      if (this.modeEditType == "edit") {
        this.modeSetInfo.modeList.splice(this.chooseModeIndex, 1, modeItem);
      } else {
        this.modeSetInfo.modeList.push(modeItem);
      }
      this.showModeEdit = false;
    },

    /**
     * 获取滤芯规则
     */
    async getSurplusRule() {
      let res = await querySurplusRules(null, { size: 999, page: 1 });
      if (res.errorCode === 0 && res.data) {
        const list = res.data.list || [];
        this.surplusList1 = list.filter((i) => +i.code === 1);
        this.surplusList2 = list.filter((i) => +i.code === 2);
        this.surplusList3 = list.filter((i) => +i.code === 3);
        this.surplusList4 = list.filter((i) => +i.code === 4);
      }
    },

    // 删除滤芯
    deleteFilter(index) {
      this.filterList.splice(index, 1);
    },

    // 设为主数据点
    toSetMainPoint(item) {
      console.log( this.brandInfo.mainPoint,item.code)
      let tipsList=['是否将该数据点（主检测数据）移除?','是否将该数据点设置为主检测数据?']
      let tips=this.brandInfo.mainPoint===item.code?0:1
      this.$confirm(`${tipsList[tips]}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if(tips){
          this.brandInfo.mainPoint = item.code;
          }else{
            this.brandInfo.mainPoint = null;
          }
          this.$notify.success({
            title: "成功",
            message: "设置成功",
          });
          console.log( this.brandInfo.mainPoint,item.code,'success')
        })
        .catch(() => {});
    },

    // 点击设置
    handleSubmit() {
      this.dataPointList = this.$refs.transferList.selectList || {};
      this.$refs.baseForm.validate(async (baseValid) => {
        if (baseValid) {
          this.$refs.modeForm.validate(async (modeValid) => {
            if (modeValid) {
              if (!this.dataPointList.length) {
                return this.$message("请添加数据点");
              }
              if (!this.brandInfo.mainPoint) {
                return this.$message("请设置该型号主要监控的数据点");
              }
              // if (!this.filterList.length) {
              //   return this.$message("请添加滤芯");
              // }

              // 转换字段
              this.brandInfo.dataPointJson = JSON.stringify(this.dataPointList);
              this.brandInfo.mainFuncJson = JSON.stringify(this.mainFuncInfo);
              this.brandInfo.modeJson = JSON.stringify(this.modeSetInfo);
              this.brandInfo.filterJson = JSON.stringify(this.filterList);
              let resData = null;
              if (this.isEdit) {
                resData = await updateBrand(this.brandInfo);
              } else {
                delete this.brandInfo.id;
                resData = await createBrand(this.brandInfo);
              }

              if (resData.errorCode === 0 && resData.data) {
                this.$notify.success({
                  title: "成功",
                  message: `${this.isEdit ? "编辑" : "新增"}成功`,
                });
                this.$router.back();
              }
            }
          });
        }
      });
    },

    // 上传图片
    async uploadImg(e, type) {
      const resData = await uploadImageFile(e.file);
      if (resData.errorCode === 0 && resData.data) {
        const imgInfo = resData.data || {};
        if (type == 2) {
          this.mainFuncInfo.icon = imgInfo.fpicurl;
        } else {
          this.brandInfo.images = imgInfo.fpicurl;
        }
      }
    },

    // 获取产品型号
    async queryDevTypes(key = "") {
      const resData = await searchDevicesByType({ key });
      if (resData.errorCode === 0 && resData.data) {
        const dataList = resData.data.list || [];
        this.deviceTypeList = dataList.map((item) => {
          return {
            id: item.id,
            name: item.name,
            deviceTypeCode: item.deviceTypeCode,
          };
        });
      }
    },

    // 获取品牌商
    async queryCustomers(key = "") {
      const resData = await getAllCustomers({ key });
      if (resData.errorCode === 0 && resData.data) {
        const dataList = resData.data.list || [];
        this.customerList = dataList.slice(0, 1);
      }
    },

    // 获取品牌详情
    async queryBrandInfo(id) {
      if (!id) return;
      const resData = await getBrandInfo(id);
      if (resData.errorCode === 0 && resData.data) {
        this.brandInfo = resData.data || {};

        // 转换数据
        if (this.brandInfo.dataPointJson) {
          this.dataPointList = JSON.parse(this.brandInfo.dataPointJson);
          this.$nextTick(() => {
            this.$refs.transferList.fetchTableData(this.dataPointList);
          });
        }

        if (this.brandInfo.mainFuncJson) {
          this.mainFuncInfo = JSON.parse(this.brandInfo.mainFuncJson);
        }
        if (this.brandInfo.modeJson) {
          this.modeSetInfo = JSON.parse(this.brandInfo.modeJson);
        }
        if (this.brandInfo.filterJson) {
          this.filterList = JSON.parse(this.brandInfo.filterJson);
        }

        // 型号数据初始化
        if (
          this.deviceTypeList.every(
            (item) => item.id != this.brandInfo.deviceTypeId
          )
        ) {
          this.deviceTypeList.push({
            id: this.brandInfo.id,
            name: this.brandInfo.deviceTypeName,
          });
        }
      }
    },
  },
  mounted() {
    this.queryDevTypes();
    this.queryCustomers();
    this.getSurplusRule();
    const params = this.$route.params || {};
    if (params && params.id) {
      this.isEdit = true;
      this.brandInfo.id = params.id;
      this.queryBrandInfo(params.id);
    } else {
      this.isEdit = false;
      this.$nextTick(() => {
        this.$refs.transferList.fetchTableData();
      });
    }
  },
};
</script>
