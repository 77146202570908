import { render, staticRenderFns } from "./mode-edit.vue?vue&type=template&id=f5361bfe&scoped=true&"
import script from "./mode-edit.vue?vue&type=script&lang=js&"
export * from "./mode-edit.vue?vue&type=script&lang=js&"
import style0 from "./mode-edit.vue?vue&type=style&index=0&id=f5361bfe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5361bfe",
  null
  
)

export default component.exports